import { Button, Link } from "@mui/material";
import React from "react";
import "./style.css";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export const NewBody = (props: any) => {
  return (
    <div className="new-body">
      <Link className="btn1" href="https://pitch.com/public/f38e7740-b797-463b-b790-f4fabacd99d3" color="inherit" underline="none" target="_blank">
      <div style={{display:"flex",flexDirection:"column",backgroundColor:"#850E35",textAlign:"center",padding:"5%"}}>
      Are you a fintech/ NBFC getting affected by the new digital lending guidelines? 
        <div style={{display:"flex",flexDirection:"column",alignItems:"flex-end",backgroundColor:"#850E35"}}>
        <KeyboardDoubleArrowRightIcon style={{backgroundColor:"#850E35", paddingTop:"5%"}}/>

        </div>
        </div>
        
      </Link>
      <Link className="btn2" href="https://pitch.com/embed/9e351ba8-247e-4910-bc12-9edd3316694d" color="inherit" underline="none" target="_blank">
        <div style={{display:"flex",flexDirection:"column",backgroundColor:"#422b01"}}>
        Want to know more about mewt?
        <div style={{display:"flex",flexDirection:"column",alignItems:"flex-end",backgroundColor:"#422b01"}}>
        <KeyboardDoubleArrowRightIcon style={{backgroundColor:"#422b01", paddingTop:"5%"}}/>

        </div>
        </div>
        
      </Link>
      <Link className="contact" href="https://wa.link/qppxvt" color="inherit" underline="none">
        <div style={{display:"flex",flexDirection:"row",backgroundColor:"#2B580C"}}>
        CONTACT
        <WhatsAppIcon style={{backgroundColor:"#2B580C", paddingLeft:"5%"}}/>
        </div>
        
      </Link>
    </div>
  );
};
