import React from "react";
// @ts-ignore
import mewtIcon from "./../../assets/mewt.png";
import "./style.css";

export const Header = (props: any) => {
  return (
    <div className="header">
      <div className="header_left"></div>
      <div className="header_center">
        <img className="header_icon" alt="icon" src={mewtIcon} />
      </div>
      <div className="header_right">
        {/* <Link href="#" underline="hover" variant="h5" className="about">
        about us
    </Link> */}
      </div>
    </div>
  );
};
