import React from 'react';
import './App.css';
import { Body } from './components/Body';
import { Header } from './components/header';
// @ts-ignore
import whatsappIcon from "./assets/whatsapp.png"
import { NewBody } from './components/NewBody';

function App() {
  return (
    <div className="App">
      <div className='app-header'>
      <Header/>
      </div>
      <div className='app-body'>
      <NewBody/>
      </div>
    </div>
  );
}

export default App;
